<template>
  <div class="invitations-item">
    <div class="font-weight-bold mb-3">
      <router-link class="disabled--text" to="/invitation">Invitations</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.customer ? `${data.customer.first_name} ${data.customer.last_name}` : '' }}</span>
    </div>
    <v-card outlined min-height="70vh" color="d-flex flex-column">
      <div class="pl-lg-9 px-sm-7 pt-sm-7 pt-3 px-3 item-content">
        <div class="width50">
          <div class="font-weight-bold mb-2">Customer</div>
          <v-card flat height="48" class="gray lighten-4 px-3 d-flex align-center mb-5">
            <div class="primary--text text--darken-1 text-decoration-underline text-truncate">
              {{ data.customer ? `${data.customer.first_name} ${data.customer.last_name}` : '' }}
            </div>
            <v-spacer></v-spacer>
            <v-btn v-if="data.customer" :to="`/customers/${data.customer.id}`" target="_blank" icon small>
              <v-icon class="f18" color="primary darken-1">mdi-open-in-new</v-icon>
            </v-btn>
          </v-card>
          <div class="font-weight-bold mb-2">Phone number</div>
          <v-card flat height="48" class="gray lighten-4 px-3 d-flex align-center mb-5">
            <div class="text--text text-truncate d-flex align-center">
              <img
                v-if="data.customer_phone"
                max-width="28"
                class="me-2"
                :src="flagImg(data.customer_country_code)"
                :srcset="flagImgSet(data.customer_country_code)"
              />
              {{ data.customer_phone ? flagList.find((i) => i.ct == data.customer_country_code).cl : '' }}{{ data.customer_phone }}
            </div>
          </v-card>
          <div class="font-weight-bold mb-2">Email</div>
          <v-card flat height="48" class="gray lighten-4 px-3 d-flex align-center mb-5">
            <div class="text--text text-truncate">
              {{ data.customer_email }}
            </div>
          </v-card>
          <div class="font-weight-bold mb-2">Trip</div>
          <v-card flat height="48" class="gray lighten-4 px-3 d-flex align-center mb-5">
            <div class="primary--text text--darken-1 text-decoration-underline text-truncate">
              {{ data.trip ? data.trip.title : '' }}
            </div>
            <v-spacer></v-spacer>
            <v-btn v-if="data.trip" :to="`/trips/${data.trip.id}/general`" target="_blank" icon small>
              <v-icon class="f18" color="primary darken-1">mdi-open-in-new</v-icon>
            </v-btn>
          </v-card>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-divider></v-divider>
      <div class="text-end py-3 px-sm-10 px-3">
        <v-btn @click="modal = true" depressed outlined class="rounded" color="gray lighten-1" height="30" width="106">
          <span class="error--text text-body-2">Delete</span>
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="modal" max-width="460">
      <v-card flat outlined class="pa-8 pt-5">
        <div class="text-center text-h6 font-weight-bold mb-5">Do you really want remove invitation</div>
        <div class="text-end mt-2">
          <v-btn @click="removeItem" depressed class="primary rounded text-body-2" width="106" height="30">Remove</v-btn>
          <v-btn @click="modal = false" depressed class="ms-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import json from '@/codes.json';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      flagList: json,
      modal: false,
    };
  },
  mounted() {
    this.$store.dispatch('getInvitationItem');
  },
  methods: {
    flagImg(code) {
      return require(`@/assets/icon/flags/img_flag_${code}.png`);
    },
    flagImgSet(code) {
      return `
        ${require(`@/assets/icon/flags/2x/img_flag_${code}.png`)} 2x,
        ${require(`@/assets/icon/flags/3x/img_flag_${code}.png`)} 3x
      `;
    },
    async removeItem() {
      await this.$store.dispatch('removeInvitationItem').then(() => {
        this.modal = false;
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.invitation;
    },
  },
  destroyed() {
    this.$store.dispatch('setInvitationItem');
  },
};
</script>
